import React, { Fragment } from 'react'
import Page from '../../components/page'
import Stepper from '../../components/stepper'
import withRoot from '../../withRoot'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

import { Link } from 'gatsby'

import trydemo from '../../static/demo/trydemo.png'
// import demo1 from '../../static/demo/demo1.png'
// import demo2 from '../../static/demo/demo2.png'
import lock from '../../static/demo/lock.png'
import install05 from '../../static/fitface-install/05.jpg'
import install07 from '../../static/fitface-install/07.jpg'

const styles = theme => ({
  link: {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
    '&:hover': {
      color: theme.palette.secondary.light,
    },
  },
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
})

@withRoot
@withStyles(styles)
export default class Demo extends React.Component {
  render() {
    const { classes } = this.props

    const STEPS = [
      {
        label: 'Select a paid design from the gallery',
        content: (
          <Fragment>
            <Typography>Just click on the <b>+</b> button then <b>Try demo</b> to select the design.</Typography>
            <Typography>A message will confirm the design has been added in your demo selection.</Typography>
          </Fragment>
        ),
        img: trydemo
      },
      { label: 'Open the settings of the FitFace clockface in the Fitbit app on your phone', content: '', img: '' },
      {
        label: 'Touch "Select another design"',
        content: '',
        img: install05
      },
      {
        label: 'Touch the Demo design',
        content: (
          <Fragment>
            <Typography>This demo is only visible only when a demo is available.</Typography>
            <Typography>You may have to click on the <b>Refresh</b> button if the demo toggle is not there.</Typography>
          </Fragment>
        ),
        img: install07
      },
      {
        label: 'The upload will start',
        content: (
          <Fragment>
            <Typography>You will feel a small vibration, a loading screen will appear on your watch and phone.</Typography>
            <Typography>You can test the demo during 15 minutes.</Typography>
          </Fragment>
        ),
        img: ''
      },
      {
        label: 'When the trial period expires, a dialog appears.',
        content: (
          <Fragment>
            <Typography>If you want to buy this design, just buy it from our <Link className={classes.link} to="/user/buydesign">gallery</Link>.</Typography>
            <Typography>Or install another design from your FitFace <Link className={classes.link} to="/user/collections">collection</Link>.</Typography>
          </Fragment>
        ),
        img: lock
      },
      {
        label: 'If you bought the design, select it from your purchases list',
        content: (
          <Fragment>
            <Typography>Open the settings of the FitFace clockface in the Fitbit app on your phone.</Typography>
            <Typography>Log in if not done already.</Typography>
            <Typography>Select the design from the Purchases list to remove the Demo Ended dialog.</Typography>
          </Fragment>
        ),
        img: ''
      },
    ]

    return (
      <Page toggleTheme={this.props.toggleTheme} lightTeme={this.props.lightTeme} title="How to install a demo?">
        <Typography className={classes.parag}>
          If you want to test a paid design, you are not obliged to buy it immediately.<br />
          You can install a demo and play with if for free during 15 minutes.
        </Typography>
        <Stepper steps={STEPS} />
      </Page>
    )
  }
}
